/* eslint-disable max-len */
import React from 'react'

const CollectionIcon: React.FC = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M33.0419 0H11.7034C10.0015 0 8.59686 1.14367 8.16162 2.67711H28.0211C31.3438 2.67711 34.0471 5.38039 34.0471 8.70313V34.8399C35.6044 34.4004 36.7242 32.9663 36.7242 31.2969V3.68227C36.7242 1.62453 35.0531 0 33.0419 0V0Z' fill='currentColor' />
    <path d='M24.3387 24.0344V15.9665L17.3517 11.9324L10.3647 15.9665V24.0344L17.3517 28.0686L24.3387 24.0344ZM16.3766 25.0441L13.4476 20.6502C13.1851 20.2565 13.1851 19.7437 13.4476 19.35L16.3766 14.9568C16.8196 14.2668 17.8838 14.2669 18.3267 14.9568L21.2558 19.35C21.5182 19.7437 21.5182 20.2565 21.2558 20.6502L18.3267 25.0441C17.8838 25.7342 16.8195 25.734 16.3766 25.0441Z' fill='currentColor' />
    <path d='M28.0209 5.02075H6.68227C4.62477 5.02075 3 6.69185 3 8.70302V36.3176C3 38.3752 4.67109 39.9999 6.68227 39.9999H28.0209C30.0787 39.9999 31.7031 38.3287 31.7031 36.3176V8.70302C31.7031 6.64528 30.032 5.02075 28.0209 5.02075ZM8.02086 15.2897C8.02086 14.871 8.24422 14.4841 8.6068 14.2748L16.7656 9.56404C17.1283 9.35466 17.5749 9.35466 17.9376 9.56404L26.0964 14.2748C26.459 14.4842 26.6824 14.871 26.6824 15.2897V24.7106C26.6824 25.1293 26.459 25.5162 26.0964 25.7254L17.9376 30.4362C17.5749 30.6456 17.1283 30.6456 16.7656 30.4362L8.6068 25.7254C8.24422 25.5161 8.02086 25.1293 8.02086 24.7106V15.2897ZM25.5104 34.979H9.19274C7.63953 34.9214 7.6407 32.6924 9.19274 32.6353H25.5104C27.0636 32.693 27.0624 34.922 25.5104 34.979Z' fill='currentColor' />
    <path d='M18.8723 19.9999L17.3517 17.7192L15.8311 19.9999L17.3517 22.281L18.8723 19.9999Z' fill='currentColor' />
  </svg>

)

export default CollectionIcon