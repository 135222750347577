/* eslint-disable max-len */
import React from 'react'

const HistoryIcon: React.FC = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M38 20.0111C38.0031 23.4095 37.0448 26.7395 35.2358 29.6164C33.4269 32.4934 30.841 34.8 27.7769 36.2698C24.7128 37.7396 21.2954 38.3127 17.9194 37.9229C14.5434 37.5331 11.3466 36.1963 8.69802 34.0669C8.50745 33.9152 8.35063 33.7255 8.2376 33.5097C8.12457 33.294 8.05782 33.0571 8.04162 32.8141C8.02542 32.5711 8.06013 32.3273 8.14352 32.0985C8.22691 31.8697 8.35715 31.6608 8.52591 31.4852L9.34344 30.6677C9.64494 30.3668 10.0458 30.1863 10.4709 30.1599C10.896 30.1335 11.3162 30.263 11.6526 30.5242C13.6574 32.1114 16.0757 33.0896 18.6202 33.3426C21.1646 33.5955 23.7282 33.1126 26.0062 31.9511C28.2842 30.7897 30.1809 28.9986 31.4707 26.7908C32.7605 24.583 33.3893 22.0512 33.2824 19.4964C33.1754 16.9417 32.3371 14.4713 30.8672 12.3791C29.3973 10.2868 27.3576 8.66055 24.9905 7.69362C22.6233 6.7267 20.0283 6.45977 17.5139 6.92455C14.9995 7.38933 12.6714 8.56627 10.8064 10.3155L14.4924 14.0015C14.6522 14.1667 14.76 14.3751 14.8027 14.6009C14.8453 14.8267 14.8209 15.06 14.7324 15.2721C14.6439 15.4841 14.4953 15.6656 14.3048 15.7941C14.1143 15.9226 13.8903 15.9925 13.6606 15.9952H3.14743C2.8418 15.9914 2.54998 15.8673 2.3352 15.6499C2.12042 15.4324 1.99999 15.1391 2.00002 14.8334V4.23423C1.99874 4.00438 2.06567 3.77932 2.19234 3.58752C2.31901 3.39572 2.49972 3.24581 2.71162 3.15675C2.92352 3.06769 3.15707 3.04349 3.38273 3.08721C3.60838 3.13093 3.816 3.2406 3.9793 3.40235L7.56495 6.98801C10.1143 4.55216 13.3228 2.91866 16.7923 2.29032C20.2618 1.66198 23.8395 2.06647 27.0811 3.45356C30.3228 4.84066 33.0857 7.14928 35.0268 10.0928C36.9679 13.0363 38.0017 16.4852 38 20.0111Z' fill='currentColor' />
    <path d='M24.8762 25.7485L25.579 24.8449C25.7218 24.6648 25.8273 24.458 25.8895 24.2367C25.9517 24.0153 25.9693 23.7839 25.9413 23.5557C25.9132 23.3275 25.8402 23.1071 25.7263 22.9074C25.6124 22.7077 25.4599 22.5327 25.2778 22.3924L22.3232 20.0115V12.4673C22.3251 12.2369 22.2812 12.0086 22.1939 11.7954C22.1067 11.5822 21.9778 11.3886 21.815 11.2257C21.6521 11.0628 21.4585 10.934 21.2453 10.8468C21.0321 10.7595 20.8037 10.7156 20.5734 10.7175H19.4117C19.1825 10.7175 18.9557 10.7628 18.7442 10.8509C18.5327 10.939 18.3408 11.0682 18.1794 11.2308C18.0181 11.3935 17.8905 11.5865 17.8042 11.7987C17.7178 12.0109 17.6743 12.2382 17.6762 12.4673V22.3063L22.4236 26.0067C22.7837 26.2962 23.2436 26.4315 23.7031 26.3831C24.1626 26.3348 24.5843 26.1066 24.8762 25.7485Z' fill='currentColor' />
  </svg>

)

export default HistoryIcon