/* eslint-disable max-len */
import React from 'react'

const Fight: React.FC = () => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10.2155 15.4306L6.56947 11.7846C5.81554 11.0306 4.58892 11.0306 3.83499 11.7846C3.58332 12.0362 3.58332 12.4444 3.83499 12.6961L9.30399 18.1651C9.55566 18.4168 9.96381 18.4168 10.2155 18.1651C10.9695 17.4111 10.9695 16.1845 10.2155 15.4306Z' fill='currentColor' />
    <path d='M4.74654 15.4303L2.92358 17.2533L4.74654 19.0762L6.56949 17.2533L4.74654 15.4303Z' fill='currentColor' />
    <path d='M3.83486 19.9887L2.01162 18.1655C1.27071 17.4245 0.000257811 17.9508 0 18.9987V20.0669C0 20.6001 0.216733 21.0829 0.567013 21.4332C0.917293 21.7835 1.40017 22.0002 1.93337 22.0002H3.00153C4.04906 22.0001 4.57559 20.7294 3.83486 19.9887Z' fill='currentColor' />
    <path d='M21.2654 0.00579239L14.885 0.917328C14.7252 0.940015 14.5796 1.02234 14.4773 1.14699L6.88623 10.2785L8.84826 12.2405L14.773 6.31573C15.025 6.0638 15.4326 6.0638 15.6845 6.31573C15.9364 6.56765 15.9364 6.97534 15.6845 7.22722L9.75975 13.152L11.7218 15.114L20.8533 7.52302C20.9779 7.42066 21.0602 7.27509 21.0829 7.11533L21.9944 0.734797C22.0523 0.333342 21.6669 -0.0520863 21.2654 0.00579239Z' fill='currentColor' />
  </svg>
)

export default Fight