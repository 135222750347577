/* eslint-disable max-len */
import React from 'react'

const Junk: React.FC = () => (
  <svg width='43' height='47' viewBox='0 0 43 47' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M42.6707 45.9126C42.5782 45.8569 33.2123 40.2893 21.8869 40.2893C10.5303 40.2893 1.19606 45.8564 1.10308 45.9126C1.0705 45.9324 0.721924 46.1064 0.721924 46.1064H42.9999C42.9999 46.1064 42.6754 45.9155 42.6707 45.9126Z' fill='#B0BFD9' />
    <path fillRule='evenodd' clipRule='evenodd' d='M36.6507 40.2737C26.5815 36.7255 17.1887 36.7268 7.12338 40.2737L3.37178 9.75652C3.27343 8.95662 3.89828 8.25033 4.70417 8.25033H12.7647C13.4111 3.7519 17.2557 0.285339 21.8871 0.285339C26.5185 0.285339 30.3631 3.7519 31.0094 8.25033H39.07C39.8759 8.25033 40.5006 8.95662 40.4023 9.75652L36.6507 40.2737ZM9.51089 28.1181V26.733L10.5367 26.5167V19.8885L9.51089 19.6723V18.2737H14.3752C15.1587 18.2737 15.8381 18.3976 16.4133 18.6453C16.9885 18.893 17.4323 19.2466 17.7448 19.7061C18.0573 20.161 18.2136 20.7016 18.2136 21.3277C18.2136 21.9042 18.0551 22.4403 17.738 22.9357C17.4721 23.3573 17.121 23.7087 16.6847 23.9901L17.7176 26.5032L18.6552 26.7397V28.1181H15.7747L14.5438 24.5705L12.9213 24.5506V26.5167L13.9472 26.733V28.1181H9.51089ZM12.9213 22.733H14.4023C14.8643 22.733 15.2198 22.6272 15.4689 22.4155C15.718 22.1993 15.8426 21.8885 15.8426 21.4831C15.8426 21.0642 15.7158 20.7286 15.4621 20.4763C15.2085 20.2196 14.8462 20.0912 14.3752 20.0912H12.9213V22.733ZM19.8672 28.1181V26.733L20.893 26.5167V19.8885L19.8672 19.6723V18.2804H24.2966V19.6723L23.2708 19.8885V26.5167L24.2966 26.733V28.1181H19.8672ZM25.5358 26.733V28.1181H29.9721V26.733L28.9462 26.5167V24.7871H30.3457C31.1338 24.7871 31.8177 24.6519 32.3974 24.3817C32.9771 24.1114 33.4233 23.733 33.7358 23.2466C34.0528 22.7601 34.2113 22.1925 34.2113 21.5439C34.2113 20.8953 34.0528 20.3277 33.7358 19.8412C33.4233 19.3502 32.9771 18.9674 32.3974 18.6926C31.8177 18.4178 31.1338 18.2804 30.3457 18.2804H26.5616H25.5358V19.6723L26.5616 19.8885V26.5167L25.5358 26.733ZM30.3457 22.9695H28.9462V20.0912H30.3457C30.8303 20.0912 31.1972 20.2286 31.4463 20.5034C31.6999 20.7781 31.8267 21.1295 31.8267 21.5574C31.8267 21.9718 31.6999 22.3119 31.4463 22.5776C31.1972 22.8389 30.8303 22.9695 30.3457 22.9695Z' fill='#B0BFD9' />
  </svg>


)

export default Junk