/* eslint-disable max-len */
import React from 'react'

const Badge: React.FC = () => (
  <svg width='69' height='37' viewBox='0 0 69 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16.7387 7.84383H16.3276L16.0742 8.16764L8.47897 17.8726L8.07193 18.3927L8.47897 18.9128L16.0742 28.6178L16.3276 28.9416H16.7387H52.183H52.5941L52.8475 28.6178L60.4427 18.9128L60.8498 18.3927L60.4427 17.8726L52.8475 8.16764L52.5941 7.84383H52.183H16.7387Z' fill='#232B31' stroke='black' strokeWidth='1.68782' />
    <g filter='url(#filter0_d_289_5604)'>
      <path fillRule='evenodd' clipRule='evenodd' d='M15.9161 7H53.005L61.9211 18.3928L53.005 29.7856H15.9161L7 18.3928L15.9161 7ZM52.1827 28.0977H16.7384L9.14325 18.3928L16.7384 8.68782H52.1827L59.7778 18.3928L52.1827 28.0977Z' fill='url(#paint0_linear_289_5604)' />
    </g>
    <defs>
      <filter id='filter0_d_289_5604' x='0.248721' y='0.248721' width='68.4237' height='36.2881' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset />
        <feGaussianBlur stdDeviation='3.37564' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_289_5604' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_289_5604' result='shape' />
      </filter>
      <linearGradient id='paint0_linear_289_5604' x1='34.4605' y1='7' x2='34.4605' y2='29.7856' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#F0E0AC' />
        <stop offset='1' stopColor='#715C39' />
      </linearGradient>
    </defs>
  </svg>

)

export default Badge