/* eslint-disable max-len */
import React from 'react'

const StarSvg: React.FC = () => (
  <svg width='139' height='139' viewBox='0 0 139 139' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M35.2024 90.1416L30.6927 103.673C30.2527 104.99 30.5972 106.441 31.5765 107.424C32.6197 108.464 34.0962 108.719 35.3274 108.307L48.8585 103.798L45.2183 93.7818L35.2024 90.1416Z' fill='#7E633A' />
    <path d='M103.797 90.1416L93.7816 93.7818L90.1414 103.798L103.672 108.307C104.897 108.716 106.373 108.472 107.423 107.424C108.403 106.441 108.747 104.99 108.307 103.673L103.797 90.1416Z' fill='#7E633A' />
    <path d='M35.2024 48.8584L45.2183 45.2182L48.8585 35.2023L35.3274 30.6926C34.0962 30.2813 32.6197 30.5361 31.5765 31.5764C30.5972 32.5586 30.2527 34.0103 30.6927 35.3274L35.2024 48.8584Z' fill='#7E633A' />
    <path d='M103.797 48.8584L108.307 35.3273C108.747 34.0102 108.403 32.5585 107.423 31.5764C106.373 30.5284 104.897 30.2841 103.672 30.6926L90.1414 35.2023L93.7816 45.2182L103.797 48.8584Z' fill='#7E633A' />
    <path d='M129.637 66.0554L88.0628 50.9371L72.9445 9.36277C72.4177 7.91393 71.0414 6.94995 69.5 6.94995C67.9585 6.94995 66.5822 7.91393 66.0554 9.36277L50.9371 50.9371L9.36277 66.0554C7.91393 66.5822 6.94995 67.9585 6.94995 69.5C6.94995 71.0414 7.91393 72.4177 9.36277 72.9445L50.9371 88.0628L66.0554 129.638C66.5822 131.086 67.9585 132.05 69.5 132.05C71.0414 132.05 72.4177 131.086 72.9445 129.637L88.0628 88.0628L129.638 72.9445C131.086 72.4177 132.05 71.0414 132.05 69.5C132.05 67.9585 131.086 66.5822 129.637 66.0554Z' fill='#7E633A' />
  </svg>

)

export default StarSvg