/* eslint-disable max-len */
import React from 'react'

const ShopIcon: React.FC = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M16.7275 12.5922C16.7275 15.2822 22.0783 16.6901 27.3636 16.6901C32.6489 16.6901 37.9996 15.2822 37.9996 12.5922C37.9995 7.2121 16.7275 7.2121 16.7275 12.5922Z' fill='currentColor' />
    <path d='M37.6049 16.5607C37.3612 16.4135 37.0552 16.403 36.8019 16.536C34.5983 17.6917 31.2466 18.3288 27.3636 18.3288C23.4805 18.3288 20.1288 17.6917 17.9252 16.536C17.672 16.4031 17.3659 16.4135 17.1222 16.5607C16.877 16.7088 16.7275 16.9753 16.7275 17.2619V17.5092C16.7275 20.1992 22.0783 21.607 27.3636 21.607C32.6489 21.607 37.9996 20.1992 37.9996 17.5092V17.2619C37.9995 16.9753 37.8501 16.7088 37.6049 16.5607Z' fill='currentColor' />
    <path d='M6.90918 28.9841C6.90918 31.6742 12.2599 33.0819 17.5452 33.0819C22.8305 33.0819 28.1813 31.6741 28.1813 28.9841C28.1812 23.6039 6.90918 23.6039 6.90918 28.9841Z' fill='currentColor' />
    <path d='M27.7866 32.9533C27.542 32.8068 27.2368 32.7956 26.9836 32.9285C24.78 34.0843 21.4283 34.7214 17.5452 34.7214C13.6622 34.7214 10.3105 34.0843 8.10688 32.9285C7.85361 32.7957 7.54757 32.8069 7.30389 32.9533C7.0586 33.1013 6.90918 33.3679 6.90918 33.6544V33.9017C6.90918 36.5918 12.2599 37.9996 17.5452 37.9996C22.8305 37.9996 28.1813 36.5917 28.1813 33.9017V33.6544C28.1812 33.3679 28.0318 33.1013 27.7866 32.9533Z' fill='currentColor' />
    <path d='M12.6365 10.133C13.7415 10.133 14.8465 10.0706 15.9211 9.94657C16.0961 9.92655 16.2599 9.85054 16.3886 9.72966C17.6278 8.5611 19.745 7.69912 22.5112 7.2373C22.8036 7.18844 23.0473 6.98518 23.1472 6.70507C23.2318 6.46898 23.2726 6.24969 23.2726 6.03515C23.2725 0.654963 2.00049 0.654962 2.00049 6.03507C2.00049 8.72517 7.35122 10.133 12.6365 10.133Z' fill='currentColor' />
    <path d='M12.6365 15.0508C13.314 15.0508 13.9748 15.0276 14.6148 14.9852C14.8729 14.9675 15.107 14.8299 15.2476 14.6122C15.3875 14.3952 15.417 14.1239 15.3275 13.8814C15.1685 13.4532 15.0911 13.0314 15.0951 12.548C15.0951 12.3239 15.0032 12.1094 14.8409 11.955C14.6796 11.8005 14.4518 11.7068 14.2377 11.7292C13.7032 11.7548 13.1686 11.7724 12.6365 11.7724C8.75349 11.7724 5.40176 11.1353 3.19819 9.97958C2.94492 9.84673 2.63888 9.85716 2.3952 10.0044C2.1499 10.1525 2.00049 10.4191 2.00049 10.7056V10.9529C2.00049 13.643 7.35122 15.0508 12.6365 15.0508Z' fill='currentColor' />
    <path d='M12.6365 19.9684C13.314 19.9684 13.9748 19.9452 14.6148 19.9028C14.8729 19.8851 15.107 19.7475 15.2476 19.5298C15.3875 19.3128 15.417 19.0415 15.3275 18.799C15.1685 18.3708 15.0911 17.949 15.0911 17.4656C15.0911 17.2415 14.9992 17.027 14.837 16.8726C14.6756 16.7181 14.4518 16.63 14.2337 16.6468C13.7008 16.6724 13.1678 16.69 12.6365 16.69C8.75349 16.69 5.40176 16.0529 3.19819 14.8972C2.94492 14.7643 2.63888 14.7748 2.3952 14.922C2.1499 15.0701 2.00049 15.3367 2.00049 15.6232V15.8705C2.00049 18.5605 7.35122 19.9684 12.6365 19.9684Z' fill='currentColor' />
    <path d='M37.6054 26.3963C37.3602 26.2467 37.0557 26.2386 36.8025 26.3715C35.2628 27.1791 33.0967 27.7433 30.5393 28.0019C30.1078 28.0459 29.8002 28.5502 29.8186 28.984C29.8186 29.3994 29.7515 29.7963 29.614 30.1957C29.5229 30.4607 29.5733 30.7544 29.7475 30.9737C29.9041 31.1698 30.1398 31.2827 30.3875 31.2827C30.4162 31.2827 30.4458 31.2811 30.4754 31.2778C35.187 30.7688 38.0002 29.2985 38.0002 27.3448V27.0974C38.0001 26.8109 37.8507 26.5444 37.6054 26.3963Z' fill='currentColor' />
    <path d='M17.5461 23.2471L18.0886 23.2566C18.0935 23.2566 18.0982 23.2566 18.1031 23.2566C18.485 23.2566 18.8165 22.9917 18.9012 22.6179C18.9867 22.2386 18.795 21.852 18.4418 21.6911L18.2668 21.6126C18.1502 21.5614 18.0327 21.5101 17.924 21.4533C17.6692 21.3196 17.3672 21.3316 17.1219 21.4797C16.8774 21.6277 16.728 21.8935 16.728 22.18V22.4273C16.728 22.8805 17.0939 23.2471 17.5461 23.2471Z' fill='currentColor' />
    <path d='M37.6051 21.4787C37.3614 21.3314 37.0553 21.321 36.8021 21.4539C34.5985 22.6097 31.2468 23.2468 27.3637 23.2468L26.8236 23.2363C26.4936 23.2083 26.0989 23.4989 26.015 23.8774C25.9303 24.256 26.1221 24.6418 26.4745 24.8018C27.3605 25.2052 28.0844 25.6847 28.6253 26.2265C28.7795 26.3802 28.9873 26.4658 29.2038 26.4658C29.2206 26.4658 29.2382 26.465 29.2557 26.4642C33.289 26.2081 37.9998 24.9971 37.9998 22.4271V22.1798C37.9997 21.8933 37.8503 21.6267 37.6051 21.4787Z' fill='currentColor' />
    <path d='M8.84292 24.6311C8.94517 24.6311 9.04749 24.612 9.14413 24.5735C10.5855 24.0029 12.3432 23.6098 14.3695 23.4058C14.8009 23.3626 15.1093 22.8167 15.0909 22.3829C15.0909 22.1588 14.999 21.9443 14.8368 21.7899C14.6754 21.6354 14.4517 21.5529 14.2335 21.5641C13.7006 21.5897 13.1677 21.6073 12.6364 21.6073C8.75334 21.6073 5.40161 20.9702 3.19804 19.8145C2.94477 19.6816 2.63873 19.6921 2.39504 19.8393C2.1499 19.9874 2.00049 20.2538 2.00049 20.5404V20.7877C2.00049 22.5989 4.5157 24.0332 8.72948 24.6231C8.76707 24.6287 8.80542 24.6311 8.84292 24.6311Z' fill='currentColor' />
  </svg>

)

export default ShopIcon