/* eslint-disable max-len */
import React from 'react'

const PlaySvg: React.FC = () => (
  <svg width='292' height='292' viewBox='0 0 292 292' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g filter='url(#filter0_d_301_7298)'>
      <circle cx='146' cy='146' r='124' fill='url(#paint0_linear_301_7298)' />
    </g>
    <g filter='url(#filter1_d_301_7298)'>
      <circle cx='146' cy='146' r='134' fill='#060A12' />
    </g>
    <circle cx='146' cy='146' r='117' fill='url(#paint1_linear_301_7298)' />
    <circle cx='146' cy='146' r='115' fill='#3A3A3F' />
    <g clipPath='url(#clip0_301_7298)'>
      <g filter='url(#filter2_d_301_7298)'>
        <circle cx='146' cy='146' r='106' fill='url(#paint2_radial_301_7298)' />
      </g>
      <circle cx='146' cy='146' r='105' stroke='white' strokeOpacity='0.25' strokeWidth='2' />
      <circle cx='146' cy='146' r='100.5' stroke='url(#paint3_linear_301_7298)' />
    </g>
    <g className='outer-circle'>
      <g filter='url(#filter3_d_301_7298)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M154.377 283.75C151.606 283.916 148.813 284 146 284C142.95 284 139.922 283.901 136.921 283.706L145.627 275L154.377 283.75Z' fill='#7E633A' />
      </g>
      <g filter='url(#filter4_d_301_7298)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M158.089 279.462L145.627 267L133.228 279.399L129.593 283.035C128.121 282.861 126.657 282.663 125.2 282.443C58.8537 272.413 8 215.145 8 146C8 69.7847 69.7847 8 146 8C222.215 8 284 69.7847 284 146C284 215.253 232.988 272.592 166.489 282.489C164.915 282.724 163.331 282.932 161.74 283.112L158.089 279.462ZM162.939 278.94C228.948 270.613 280 214.268 280 146C280 71.9938 220.006 12 146 12C71.9938 12 12 71.9938 12 146C12 214.04 62.7115 270.237 128.4 278.855L145.627 261.627L162.939 278.94Z' fill='#7E633A' />
      </g>
    </g>
    <defs>
      <filter id='filter0_d_301_7298' x='14' y='14' width='264' height='264' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset />
        <feGaussianBlur stdDeviation='4' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_301_7298' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_301_7298' result='shape' />
      </filter>
      <filter id='filter1_d_301_7298' x='4' y='4' width='284' height='284' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset />
        <feGaussianBlur stdDeviation='4' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_301_7298' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_301_7298' result='shape' />
      </filter>
      <filter id='filter2_d_301_7298' x='28' y='28' width='236' height='236' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset />
        <feGaussianBlur stdDeviation='6' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_301_7298' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_301_7298' result='shape' />
      </filter>
      <filter id='filter3_d_301_7298' x='128.921' y='267' width='33.4561' height='25' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset />
        <feGaussianBlur stdDeviation='4' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_301_7298' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_301_7298' result='shape' />
      </filter>
      <filter id='filter4_d_301_7298' x='0' y='0' width='292' height='291.112' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
        <feOffset />
        <feGaussianBlur stdDeviation='4' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_301_7298' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_301_7298' result='shape' />
      </filter>
      <linearGradient id='paint0_linear_301_7298' x1='146' y1='22' x2='146' y2='270' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#E3D8BC' />
        <stop offset='1' stopColor='#423221' />
      </linearGradient>
      <linearGradient id='paint1_linear_301_7298' x1='146' y1='29' x2='146' y2='263' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#D3D1C1' />
        <stop offset='1' stopColor='#B8B2A4' />
      </linearGradient>
      <radialGradient id='paint2_radial_301_7298' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(146 146) rotate(90) scale(106)'>
        <stop stopColor='#254180' />
        <stop offset='1' stopColor='#229BEC' />
      </radialGradient>
      <linearGradient id='paint3_linear_301_7298' x1='146' y1='45' x2='146' y2='247' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#508ECF' />
        <stop offset='0.497405' stopColor='#519AE6' stopOpacity='0' />
        <stop offset='1' stopColor='#ABC4DE' />
      </linearGradient>
      <clipPath id='clip0_301_7298'>
        <rect x='40' y='40' width='212' height='212' rx='106' fill='white' />
      </clipPath>
    </defs>
  </svg>

)

export default PlaySvg