/* eslint-disable max-len */
import React from 'react'

const PlusSvg: React.FC = () => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11 0C4.92435 0 0 4.92435 0 11C0 17.0757 4.92435 22 11 22C17.0757 22 22 17.0788 22 11C22 4.92121 17.0757 0 11 0ZM16.134 11.6977C16.134 12.1826 15.7367 12.5768 15.2549 12.5768H12.6144V15.2173C12.6144 15.7022 12.2171 16.0964 11.7353 16.0964H10.268C9.78307 16.0964 9.38883 15.6991 9.38883 15.2173V12.5768H6.74833C6.26342 12.5768 5.86918 12.1795 5.86918 11.6977V10.2304C5.86918 9.74549 6.26649 9.35125 6.74833 9.35125H9.38883V6.71074C9.38883 6.22584 9.78614 5.83164 10.268 5.83164H11.7353C12.2202 5.83164 12.6144 6.22894 12.6144 6.71074V9.35125H15.2549C15.7398 9.35125 16.134 9.7486 16.134 10.2304V11.6977Z'
      fill='currentColor'
    />
  </svg>
)

export default PlusSvg
