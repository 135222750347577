export const CARDS = [
  {
    id: '0',
    type: 'bot',
    name: 'Ball Bot',
    image: 'https://assets.iotabots.io/tcg/1.png',
    mana: 1,
    attack: 1,
    defense: 1,
    hits: 1,
  },
  {
    id: '1',
    type: 'bot',
    name: 'Ball Bot',
    image: 'https://assets.iotabots.io/tcg/1.png',
    mana: 1,
    attack: 1,
    defense: 1,
    hits: 1,
  },
  {
    id: '2',
    type: 'bot',
    name: 'Bi Bot',
    image: 'https://assets.iotabots.io/tcg/2.png',
    mana: 0,
    attack: 0,
    defense: 1,
    hits: 1,
  },
  {
    id: '3',
    type: 'bot',
    name: 'Big Bot',
    image: 'https://assets.iotabots.io/tcg/3.png',
    mana: 3,
    attack: 2,
    defense: 4,
    hits: 1,
  },
  {
    id: '4',
    type: 'bot',
    name: 'Carry Bot',
    image: 'https://assets.iotabots.io/tcg/4.png',
    mana: 1,
    attack: 0,
    defense: 1,
    hits: 1,
  },
  {
    id: '5',
    type: 'bot',
    name: 'Chroma Bot',
    image: 'https://assets.iotabots.io/tcg/5.png',
    mana: 3,
    attack: 3,
    defense: 3,
    hits: 1,
  },
  {
    id: '6',
    type: 'bot',
    name: 'Cute Bot',
    image: 'https://assets.iotabots.io/tcg/6.png',
    mana: 2,
    attack: 2,
    defense: 1,
    hits: 2,
  },
  {
    id: '7',
    type: 'bot',
    name: 'Draw Bot',
    image: 'https://assets.iotabots.io/tcg/7.png',
    mana: 5,
    attack: 1,
    defense: 2,
    hits: 1,
  },
  {
    id: '8',
    type: 'bot',
    name: 'Drone Bot',
    image: 'https://assets.iotabots.io/tcg/8.png',
    mana: 1,
    attack: 1,
    defense: 1,
    hits: 1,
  },
  {
    id: '9',
    type: 'bot',
    name: 'Heavy Bot',
    image: 'https://assets.iotabots.io/tcg/9.png',
    mana: 5,
    attack: 4,
    defense: 6,
    hits: 1,
  },
  {
    id: '10',
    type: 'bot',
    name: 'Hunter Bot',
    image: 'https://assets.iotabots.io/tcg/10.png',
    mana: 4,
    attack: 5,
    defense: 2,
    hits: 1,
  },
  {
    id: '11',
    type: 'bot',
    name: 'Kamikaze Bot',
    image: 'https://assets.iotabots.io/tcg/11.png',
    mana: 1,
    attack: 1,
    defense: 0,
    hits: 1,
  },
  {
    id: '12',
    type: 'bot',
    name: 'Loaded Bot',
    image: 'https://assets.iotabots.io/tcg/12.png',
    mana: 3,
    attack: 4,
    defense: 2,
    hits: 1,
  },
  {
    id: '13',
    type: 'bot',
    name: 'Mid Bot',
    image: 'https://assets.iotabots.io/tcg/13.png',
    mana: 2,
    attack: 2,
    defense: 1,
    hits: 1,
  },
  {
    id: '14',
    type: 'bot',
    name: 'Range Bot',
    image: 'https://assets.iotabots.io/tcg/14.png',
    mana: 2,
    attack: 3,
    defense: 1,
    hits: 1,
  },
  {
    id: '15',
    type: 'bot',
    name: 'Roaming Bot',
    image: 'https://assets.iotabots.io/tcg/15.png',
    mana: 4,
    attack: 4,
    defense: 3,
    hits: 1,
  },
  {
    id: '16',
    type: 'bot',
    name: 'Sneaky Bot',
    image: 'https://assets.iotabots.io/tcg/16.png',
    mana: 5,
    attack: 3,
    defense: 3,
    hits: 1,
  },
  {
    id: '17',
    type: 'bot',
    name: 'Tall Bot',
    image: 'https://assets.iotabots.io/tcg/17.png',
    mana: 3,
    attack: 3,
    defense: 2,
    hits: 1,
  },
  {
    id: '18',
    type: 'bot',
    name: 'Tri Bot',
    image: 'https://assets.iotabots.io/tcg/18.png',
    mana: 2,
    attack: 2,
    defense: 1,
    hits: 1,
  },
  {
    id: '19',
    type: 'bot',
    name: 'Tripple Bot',
    image: 'https://assets.iotabots.io/tcg/19.png',
    mana: 5,
    attack: 5,
    defense: 5,
    hits: 1,
  },
  {
    id: '20',
    type: 'bot',
    name: 'Turtle Bot',
    image: 'https://assets.iotabots.io/tcg/20.png',
    mana: 1,
    attack: 1,
    defense: 1,
    hits: 1,
  },
  {
    id: '21',
    type: 'bot',
    name: 'Wall Bot',
    image: 'https://assets.iotabots.io/tcg/21.png',
    mana: 4,
    attack: 2,
    defense: 5,
    hits: 1,
  },
  {
    id: '22',
    type: 'bot',
    name: 'Alpha Bot',
    image: 'https://assets.iotabots.io/tcg/22.png',
    mana: 6,
    attack: 6,
    defense: 6,
    hits: 1,
  },
]
