/* eslint-disable max-len */
import React from 'react'

const Highlight: React.FC = () => (
  <svg width='84' height='84' viewBox='0 0 84 84' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path fillRule='evenodd' clipRule='evenodd' d='M51.9863 72.4112C48.8441 73.4423 45.4873 74.0001 42.0003 74.0001C38.17 74.0001 34.4969 73.3271 31.0928 72.093L40.2844 81.2846C41.0655 82.0657 42.3318 82.0657 43.1128 81.2846L51.9863 72.4112Z' fill='#2EFFBF' />
  </svg>

)

export default Highlight