/* eslint-disable max-len */
import React from 'react'

const MinusSvg: React.FC = () => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M11 0C17.0756 0 22 4.92435 22 11C22 17.0757 17.0756 22 11 22C4.92431 22 -8.12364e-07 17.0788 -8.12364e-07 11C-8.12364e-07 4.92121 4.92431 0 11 0ZM5.86603 11.6977C5.86603 12.1826 6.26334 12.5768 6.74514 12.5768H9.38564C9.38564 12.5768 9.78295 12.5768 10.2647 12.5768H11.732C12.2169 12.5768 12.6112 12.5768 12.6112 12.5768H15.2517C15.7366 12.5768 16.1308 12.1795 16.1308 11.6977V10.2304C16.1308 9.74549 15.7335 9.35125 15.2517 9.35125H12.6112C12.6112 9.35125 12.2139 9.35125 11.732 9.35125H10.2647C9.77984 9.35125 9.38564 9.35125 9.38564 9.35125H6.74514C6.26023 9.35125 5.86603 9.7486 5.86603 10.2304V11.6977Z' fill='currentColor' />
  </svg>

)

export default MinusSvg