/* eslint-disable max-len */
import React from 'react'

const Deploy: React.FC = () => (
  <svg width='19' height='22' viewBox='0 0 19 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M6.04414 14.457C6.20821 14.5436 6.27906 14.7416 6.21013 14.9139C6.13745 15.0956 6.07998 15.2833 6.03869 15.4751C5.83874 16.4048 4.55413 16.493 4.21197 15.6176C3.73852 14.4076 3.64421 13.2414 3.91331 12.2137C4.00882 11.849 4.52924 11.8558 4.62227 12.2211C4.68831 12.4806 4.76022 12.7395 4.83803 12.9976C5.02818 13.6278 5.47097 14.1544 6.04414 14.457Z' fill='currentColor' />
    <path d='M13.9001 15.6175C13.558 16.4925 12.2733 16.405 12.0733 15.475C12.0324 15.2834 11.9751 15.096 11.9025 14.9146C11.8334 14.7422 11.9045 14.544 12.0688 14.4573C12.6405 14.156 13.083 13.6301 13.274 12.9984C13.3509 12.7432 13.4232 12.4831 13.4902 12.2194C13.5828 11.8551 14.1036 11.8502 14.1988 12.2138C14.4678 13.2415 14.3735 14.4076 13.9001 15.6175Z' fill='currentColor' />
    <path d='M9.05635 7.83975C9.86628 7.83975 10.5229 7.18317 10.5229 6.37324C10.5229 5.56332 9.86628 4.90674 9.05635 4.90674C8.24642 4.90674 7.58984 5.56332 7.58984 6.37324C7.58984 7.18317 8.24642 7.83975 9.05635 7.83975Z' fill='currentColor' />
    <path d='M5.8074 11.7895C5.54302 11.7403 5.31759 11.9838 5.3866 12.2438C5.4394 12.4426 5.49132 12.624 5.54013 12.7856C5.71198 13.3534 6.17439 13.7988 6.74725 13.9487C8.20894 14.3305 9.81165 14.3566 11.3722 13.9473C11.9414 13.7979 12.4011 13.3529 12.5725 12.786C12.6213 12.6243 12.6734 12.4427 12.7263 12.2436C12.7953 11.9837 12.5698 11.7401 12.3054 11.7894C10.1618 12.1886 7.95189 12.1883 5.8074 11.7895Z' fill='currentColor' />
    <path d='M12.7901 10.9409C12.936 10.908 13.0481 10.7915 13.073 10.6441C13.716 6.83259 13.0752 3.04383 10.2131 0.448201C9.55365 -0.1494 8.55918 -0.1494 7.89971 0.448201C5.03062 3.0501 4.39893 6.84533 5.03979 10.6445C5.06468 10.792 5.17691 10.9086 5.32283 10.9414C7.77647 11.4933 10.3407 11.4931 12.7901 10.9409ZM9.05637 4.17317C10.2694 4.17317 11.2561 5.15995 11.2561 6.37293C11.2561 7.58592 10.2694 8.57269 9.05637 8.57269C7.84343 8.57269 6.85661 7.58592 6.85661 6.37293C6.85661 5.15995 7.84343 4.17317 9.05637 4.17317Z' fill='currentColor' />
    <path d='M16.1273 22H1.98558C0.520865 22 -0.326226 20.558 0.118538 19.3122C0.55409 18.0166 2.24305 17.6316 3.39526 18.655C3.68517 18.9126 3.89915 19.2687 3.90258 19.2733C3.99974 19.4337 4.20047 19.4964 4.37049 19.4185C4.56682 19.328 4.6456 19.0864 4.53364 18.8993C4.16183 18.2818 3.6162 17.7984 2.98437 17.5392C2.69491 17.4204 2.6836 17.0159 2.96223 16.8736C4.30262 16.189 5.9693 16.8265 6.50742 18.2907C7.30987 17.6601 7.72554 16.6748 7.63434 15.5685C7.61624 15.3557 7.78456 15.1716 7.99959 15.1716H10.1132C10.3271 15.1716 10.4966 15.3547 10.4784 15.5685C10.3872 16.6748 10.8029 17.6601 11.6054 18.2907C12.1376 16.8425 13.8021 16.1799 15.1526 16.8739C15.4298 17.0163 15.4177 17.4202 15.1294 17.5383C14.4927 17.7992 13.9486 18.285 13.5792 18.8993C13.4674 19.0854 13.5453 19.3278 13.7424 19.4185C13.9124 19.4964 14.1136 19.4337 14.2103 19.2733C14.2137 19.2687 14.2491 19.2093 14.2561 19.1999C14.8161 18.3177 15.8327 17.8337 16.834 18.1674C18.8359 18.8335 18.3821 22 16.1273 22Z' fill='currentColor' />
  </svg>

)

export default Deploy