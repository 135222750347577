/* eslint-disable max-len */
import React from 'react'

const RocketSvg: React.FC = () => (
  <svg width='139' height='139' viewBox='0 0 139 139' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M54.1533 90.7496C55.1381 91.2695 55.5633 92.4581 55.1496 93.4921C54.7134 94.5825 54.3684 95.7092 54.1206 96.8607C52.9204 102.441 45.2098 102.97 43.1561 97.7161C40.3144 90.4531 39.7483 83.4536 41.3635 77.2848C41.9367 75.0958 45.0605 75.1365 45.6189 77.3294C46.0153 78.8868 46.4469 80.441 46.9139 81.9902C48.0552 85.7725 50.713 88.9333 54.1533 90.7496Z' fill='#7E633A' />
    <path d='M101.305 97.7153C99.2525 102.968 91.5413 102.442 90.3409 96.8598C90.095 95.7097 89.7514 94.5852 89.3154 93.4965C88.9009 92.4614 89.3275 91.2717 90.3137 90.7518C93.7452 88.9429 96.4013 85.7867 97.5478 81.9948C98.0094 80.4632 98.4432 78.9016 98.8453 77.3191C99.4013 75.1323 102.527 75.1029 103.099 77.2856C104.713 83.4538 104.147 90.4531 101.305 97.7153Z' fill='#7E633A' />
    <path d='M72.2335 51.0313C77.0949 51.0313 81.0359 47.0904 81.0359 42.229C81.0359 37.3676 77.0949 33.4266 72.2335 33.4266C67.3721 33.4266 63.4312 37.3676 63.4312 42.229C63.4312 47.0904 67.3721 51.0313 72.2335 51.0313Z' fill='#7E633A' />
    <path d='M52.7326 74.7384C51.1457 74.4432 49.7926 75.905 50.2069 77.4652C50.5237 78.6587 50.8354 79.7472 51.1284 80.7171C52.1599 84.1253 54.9354 86.799 58.3738 87.6985C67.1473 89.9904 76.7671 90.1469 86.1342 87.6902C89.5506 86.7935 92.3096 84.1225 93.3384 80.7199C93.6316 79.7491 93.9441 78.6593 94.2615 77.4644C94.6761 75.9039 93.3224 74.4419 91.7352 74.7376C78.8687 77.1337 65.6044 77.1321 52.7326 74.7384Z' fill='#7E633A' />
    <path d='M94.6461 69.6449C95.5214 69.4477 96.1945 68.7482 96.3438 67.8635C100.203 44.986 96.3573 22.2448 79.1781 6.66519C75.2198 3.07824 69.2507 3.07824 65.2924 6.66519C48.0715 22.2825 44.2798 45.0624 48.1265 67.8663C48.2758 68.7515 48.9495 69.451 49.8253 69.6479C64.5528 72.9606 79.9437 72.9598 94.6461 69.6449ZM72.235 29.0234C79.5156 29.0234 85.4385 34.9463 85.4385 42.227C85.4385 49.5076 79.5156 55.4305 72.235 55.4305C64.9546 55.4305 59.0315 49.5076 59.0315 42.227C59.0315 34.9463 64.9546 29.0234 72.235 29.0234Z' fill='#7E633A' />
    <path d='M114.675 136.025H29.793C21.0014 136.025 15.9169 127.37 18.5865 119.892C21.2008 112.115 31.3384 109.804 38.2542 115.947C39.9944 117.493 41.2787 119.631 41.2993 119.658C41.8825 120.621 43.0873 120.998 44.1078 120.53C45.2862 119.987 45.7591 118.536 45.0871 117.414C42.8554 113.707 39.5804 110.806 35.7879 109.25C34.0506 108.537 33.9826 106.109 35.6551 105.254C43.7004 101.145 53.7043 104.972 56.9342 113.761C61.7508 109.976 64.2457 104.061 63.6983 97.4212C63.5896 96.144 64.6 95.0391 65.8906 95.0391H78.577C79.861 95.0391 80.8785 96.1377 80.7693 97.4212C80.2219 104.061 82.7169 109.976 87.5334 113.761C90.7278 105.068 100.719 101.091 108.825 105.256C110.489 106.111 110.416 108.536 108.685 109.245C104.864 110.81 101.598 113.726 99.3811 117.414C98.7099 118.53 99.1775 119.985 100.36 120.53C101.381 120.998 102.588 120.621 103.169 119.658C103.189 119.631 103.402 119.274 103.444 119.218C106.805 113.922 112.907 111.018 118.917 113.021C130.933 117.019 128.209 136.025 114.675 136.025Z' fill='#7E633A' />
  </svg>

)

export default RocketSvg